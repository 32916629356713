/** @jsx jsx */
import { jsx, Box, Image, Flex, Text } from "theme-ui";
import { navigate } from "@reach/router";
import useAuth from "gatsby-plugin-hfn-profile/auth/useAuth";
import { Helmet } from "react-helmet";
import SignOutButton from "gatsby-plugin-hfn-profile/auth/SignOutButton";
import Icon from "sites-common/components/ui/Icon";
import PropTypes from "prop-types";
import { ActionButton } from "office-ui-fabric-react";
import React, { useCallback, useEffect, useRef } from "react";
import { useGlobalsBoolean } from "gatsby-plugin-hfn-profile/globals";
import { unsetUser } from "gatsby-plugin-hfn-profile/state/actions";
import { useDispatch } from "react-redux";
import PositionedImage from "../../assets/bannerPositionedImage.png";
import BackgroundImage from "../../assets/bannerBackgroundImage.jpg";
import ProfileImage from "../../assets/meditator.jpg";

const getMandatoryEnv = require("sites-common/utils/getMandatoryEnv");

const { keycloakConfig } = getMandatoryEnv(["keycloakConfig"]);

const headerStyle = {
  fontSize: "1.2rem",
  fontWeight: "600",
};
const descStyle = {
  fontSize: "0.8rem",
  fontWeight: "300",
  opacity: 0.5,
};

const goMain = () => navigate("/");

const SiteHeader = ({ goHome, isHome, hideHdr }) => {
  const { srcmProfile } = useAuth();
  const [embedded] = useGlobalsBoolean("embedded");
  const dispatch = useDispatch();
  const showHdr = !hideHdr;
  const elementRef = useRef();
  React.useEffect(() => {
    import("hfn-oneauth/main");
  });
  const oneauthLogout = useCallback(async () => {
    const params = keycloakConfig;
    if (typeof window !== "undefined") {
      const { userLogout } = await import("hfn-oneauth/main");
      await userLogout(params);
      dispatch(unsetUser());
      return true;
    }
    return false;
  }, [dispatch]);
  const handleUserLoggedOut = useCallback(
    async (event) => {
      if (event?.detail?.loggedOut) await oneauthLogout();
    },
    [oneauthLogout]
  );
  useEffect(() => {
    elementRef.current = document.querySelector("hfn-oneauth");

    // Add the event listener when the element is present
    if (elementRef.current) {
      elementRef.current.addEventListener("userLoggedOut", handleUserLoggedOut);
    }

    // Cleanup event listener on unmount or when the path changes
    return () => {
      if (elementRef.current) {
        elementRef.current.removeEventListener(
          "userLoggedOut",
          handleUserLoggedOut
        );
      }
    };
  }, [elementRef, handleUserLoggedOut]);

  if (embedded) {
    return (
      <div sx={{}}>
        <Helmet>
          <style>
            {"body { background-color: #E0CDD7; min-height: 100vh }"}
          </style>
        </Helmet>
        <div sx={{ bg: "transparent" }}>
          {!isHome && (
            <ActionButton
              iconProps={{ iconName: "Back" }}
              onClick={goHome}
              text="Back"
            />
          )}
        </div>
      </div>
    );
  }

  return (
    <div sx={{ backgroundColor: "white" }}>
      <Helmet>
        <style>{"body { background-color: #E0CDD7; min-height: 100vh }"}</style>
      </Helmet>
      <hfn-oneauth
        ref={elementRef}
        config={JSON.stringify(keycloakConfig)}
        showCancel
      />
      <Box
        sx={{
          width: "100%",
          background: `#e7dad2 url(${BackgroundImage}) no-repeat center / cover`,
          backgroundPosition: "center top",
          minHeight: "72px",
        }}
      >
        <Flex sx={{ alignItems: "center" }}>
          <Image
            sx={{ p: 3, minWidth: "200px", width: "25vw", cursor: "pointer" }}
            src={PositionedImage}
            alt="Heartfulness"
            role="button"
            tabIndex={0}
            onClick={goMain}
            onKeyDown={goMain}
          />

          {!isHome && (
            <ActionButton
              sx={{ position: "absolute", right: 20 }}
              iconProps={{ iconName: "Back" }}
              onClick={goHome}
            >
              Go Back
            </ActionButton>
          )}
        </Flex>
      </Box>

      <Box sx={{ m: 3, mt: "-3", mb: showHdr ? 3 : 4 }}>
        <Flex sx={{ alignItems: "center" }}>
          {showHdr && (
            <React.Fragment>
              <Image
                sx={{ width: "80px", borderRadius: "50%", mb: 1 }}
                src={
                  srcmProfile?.photoUrl ? srcmProfile?.photoUrl : ProfileImage
                }
              />
              {srcmProfile ? (
                <Box sx={{ m: 3 }}>
                  <div sx={{ mt: 1 }}>
                    <Text sx={headerStyle}>
                      {srcmProfile?.name || srcmProfile?.email}
                    </Text>
                  </div>
                  <div sx={{ mt: -1 }}>
                    <Text sx={descStyle}>{srcmProfile?.abhyasiId}</Text>
                  </div>
                </Box>
              ) : (
                <Box sx={{ m: 3 }}>
                  <Text sx={headerStyle}>My Hub</Text>
                </Box>
              )}
            </React.Fragment>
          )}
          <Box sx={{ position: "absolute", right: 20 }}>
            <SignOutButton
              btn={
                <Icon
                  title="Sign Out"
                  sx={{
                    fontSize: "1.3em",
                    "&:hover": { fontSize: "1.5em" },
                    color: "red",
                    cursor: "pointer",
                  }}
                  icon="power-off"
                />
              }
              oneauthLogout={oneauthLogout}
            />
          </Box>
        </Flex>
      </Box>
    </div>
  );
};

SiteHeader.propTypes = {
  goHome: PropTypes.func.isRequired,
  isHome: PropTypes.bool.isRequired,
  hideHdr: PropTypes.bool,
};
SiteHeader.defaultProps = {
  hideHdr: false,
};
export default SiteHeader;
