/** @jsx jsx */
// import { jsx, ThemeProvider } from "theme-ui";
import { jsx } from "theme-ui";
import { /* useDispatch, */ useSelector } from "react-redux";
import { useFirebase } from "react-redux-firebase";
import PropTypes from "prop-types";
import { DefaultButton } from "office-ui-fabric-react";
import { selectorGlobalConfig, selectorSrcmProfile } from "../state/selectors";
// import unsetUser from "../state/actions/unsetUser";
// import { useGlobals } from "../globals";

const getMandatoryEnv = require("sites-common/utils/getMandatoryEnv");

const { myloginType } = getMandatoryEnv(["myloginType"]);

const SignOutButton = ({ btn, btnText, oneauthLogout }) => {
  const srcmProfile = useSelector(selectorSrcmProfile);
  // const [isFbBlocked] = useGlobals("fb-blocked", true);
  const firebase = useFirebase();
  // const dispatch = useDispatch();
  const { enabledFireStore } = useSelector(selectorGlobalConfig);

  const doSignOut = () => {
    // if (isFbBlocked) {
    //   dispatch(unsetUser());
    // } else {
    firebase.logout();
    if (enabledFireStore) {
      firebase.app("auth").auth().signOut();
    }
    // }
  };

  if (srcmProfile) {
    return btn ? (
      <div
        onClick={() =>
          myloginType === "firebase" ? doSignOut() : oneauthLogout()
        }
        onKeyDown={() =>
          myloginType === "firebase" ? doSignOut() : oneauthLogout()
        }
        tabIndex={0}
        role="button"
      >
        {btn}
      </div>
    ) : (
      <DefaultButton
        onClick={() =>
          myloginType === "firebase" ? doSignOut() : oneauthLogout()
        }
        text={btnText}
      />
    );
  }
  return null;
};
SignOutButton.propTypes = {
  btn: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  btnText: PropTypes.string,
  oneauthLogout: PropTypes.func,
};
SignOutButton.defaultProps = {
  btn: null,
  btnText: "Sign Out",
  oneauthLogout: () => {},
};

export default SignOutButton;
