import { useLocation } from "@reach/router";

const WrapPage = require("sites-common/gatsby-utils/WrapPage").default;
const React = require("react");
const PropTypes = require("prop-types");
const Login = require("./Login").default;
const SignOutButton = require("./SignOutButton").default;
const useAuth = require("./useAuth").default;

const WrapPageAccessControl = ({
  element,
  accessControl,
  myloginType,
  props,
}) => {
  const { srcmProfile } = useAuth();
  const location = useLocation();

  if (!accessControl) {
    return <WrapPage element={element} props={props} />;
  }

  const initializeAuthProfile = async () => {
    await import("hfn-oneauth/main").then(() => {
      document.querySelector("hfn-oneauth")?.triggerAuth();
    });
  };

  if (!srcmProfile) {
    const loc = `${location?.pathname}${location?.search}`;
    localStorage.setItem("LandingPage", loc);
    if (myloginType === "keycloak") {
      initializeAuthProfile();
      return true;
    }
    return <Login />;
  }
  const { conditions, msgUnAuthorized } = accessControl;
  if (typeof conditions === "function") {
    if (conditions(srcmProfile)) {
      return <WrapPage element={element} props={props} />;
    }
  }

  return (
    <div style={{ margin: "10px", padding: "10px" }}>
      {typeof msgUnAuthorized === "function" ? (
        msgUnAuthorized(srcmProfile, SignOutButton)
      ) : (
        <div>
          {msgUnAuthorized} <br />
          <br />
          <SignOutButton btnText="Sign-in as another user" />
        </div>
      )}
    </div>
  );
};
WrapPageAccessControl.propTypes = {
  element: PropTypes.element.isRequired,
  accessControl: PropTypes.shape({
    conditions: PropTypes.func,
    msgUnAuthorized: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  }),
  props: PropTypes.shape({}),
  myloginType: PropTypes.string,
};

WrapPageAccessControl.defaultProps = {
  accessControl: undefined,
  props: undefined,
  myloginType: "firebase",
};

export default WrapPageAccessControl;
