import { PhoneNumberUtil } from "google-libphonenumber";

export const abhyasiIdRegex = "^([a-zA-Z]{6}[0-9]{3}|[HABhab]{1}[0-9]{8})$";
const abhyasiIdPermanentRegex = "^([a-zA-Z]{6}[0-9]{3})$";
const abhyasiIdTempRegex = "^([HAhaBb]{1}[0-9]{8})$";
const pnrRegex = "^[0-9A-Z]{2}-[A-Z]{4}-[A-Z]{4}$";
const emailRegex =
  '(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))';

export function validateRE(re1, txt) {
  const re = RegExp(re1);
  return re.test(String(txt));
}

export function validatePhoneNumber(phoneNumber) {
  let valid = false;
  try {
    const phoneUtil = PhoneNumberUtil.getInstance();
    valid = phoneUtil.isValidNumber(phoneUtil.parse(phoneNumber));
  } catch (e) {
    valid = false;
  }
  return valid;
}

export function validatePhoneNumberMobile(phoneNumber) {
  if (validateRE(/^[1-9][0-9]{9}$/, phoneNumber)) {
    // valid 10 digit format
    return true;
  }
  return validatePhoneNumber(phoneNumber);
}

export const validateEmail = (t) => validateRE(emailRegex, t);
export const validatePNR = (t) => validateRE(pnrRegex, t);
export const validateAbhyasiId = (t) => validateRE(abhyasiIdRegex, t);
export const validateAbhyasiIdTemp = (t) => validateRE(abhyasiIdTempRegex, t);
export const validateAbhyasiIdPermanent = (t) =>
  validateRE(abhyasiIdPermanentRegex, t);

const isNewPractitioner = (record_type, ref) =>
  ["a", "n"].includes(record_type) && validateAbhyasiIdTemp(ref);
const isPractitioner = (record_type, ref) =>
  ["a", "n"].includes(record_type) && validateAbhyasiIdPermanent(ref);

export const TRAINER = "Trainer";
export const NEWPRACTITIONER = "New Practitioner";
export const PRACTITIONER = "Practitioner";
export const SEEKER = "Seeker";
export const WHAT_IS_MEDITATION_LINK =
  "https://heartfulness.org/en/what-is-meditation/";
export const HFN_REGULAR_CARD_LINK =
  "https://hfnlife.com/products/heartfulness-regular-card";
export const FOLLOW_UP_LINK = "https://heartfulness.org/en/follow-up/";
export const TEN_MAXIMS_VIDEO_LINK =
  "https://www.youtube.com/watch?v=48GUeceJHtk&list=PL1QpxVYcCuCYtRiUo4AoWOZALqkwZUNhT";
export const PRECEPTORS_RESOURCES_LINK =
  "https://preceptor.heartfulness.org/en/preceptor";

export const ageGroup = (age) =>
  age
    ? `${Math.floor((age - 1) / 5) * 5 + 1}-${
        Math.floor((age - 1) / 5) * 5 + 5
      }`
    : ""; //  get(age_groups, (r.age / 5),  "");

export const abhyasStage = (record_type, abhyasiId) => {
  if (record_type === "t") return TRAINER;
  if (record_type === "s") return SEEKER;
  if (isNewPractitioner(record_type, abhyasiId)) {
    return NEWPRACTITIONER;
  }
  if (isPractitioner(record_type, abhyasiId)) {
    return PRACTITIONER;
  }
  return "";
};

const levenshtein = require("js-levenshtein");

function checkForCloseMatch(longString, shortString, allStrings) {
  // too many false positives with very short strings
  if (shortString.length < 3) return "";

  // test if the shortString is in the string (so everything is fine)
  if (longString.split(".").includes(shortString)) return "";

  const parts = longString.split(".");

  for (let i = 0; i < parts.length; i += 1) {
    const l = levenshtein(parts[i], shortString);
    if (l <= 1 && !allStrings.includes(parts[i])) {
      const newp = [...parts];
      newp[i] = shortString;
      return newp.join(".");
    }
  }
  return "";
}

export function checkForDomainTypo(userEmail) {
  const domains = [
    "gmail",
    "ymail",
    "mail",
    "rocketmail",
    "hotmail",
    "outlook",
    "yahoo",
    "icloud",
    "zoho",
    "yandex",
    "aol",
  ];
  const [leftPart, rightPart] = userEmail.toLowerCase().split("@");

  for (let i = 0; i < domains.length; i += 1) {
    const domain = domains[i];

    const result = checkForCloseMatch(rightPart, domain, domains);

    if (result) return `${leftPart}@${result}`;
  }

  return "";
}

export const validateAge = (age) => {
  if (age < 15) {
    return "Age should be minimum 15";
  }
  if (age > 110) {
    return "Enter a valid age";
  }
  return false;
};
